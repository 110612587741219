
import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {
        Clock: () => import("@/components/layout/Clock.vue")
    }
})
export default class Footer extends Vue {
    toggle_exclusive = 0;
    translate() {
        switch (this.toggle_exclusive) {
            case 0:
                this.$root.$i18n.locale = "es";
                break;
            case 1:
                this.$root.$i18n.locale = "en";
                break;
            case 2:
                this.$root.$i18n.locale = "fr";
                break;
            default:
                this.$root.$i18n.locale = "es";
                break;
        }
    }
}
